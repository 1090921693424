<template>
    <section id="intro">
    <div class="container-lg">
      <div class="row g-4 justify-content-center align-items-center">
        <h3 class="text-primary">Agility Shows Online - Terms & Conditions</h3>
        <p>Welcome to Agility Shows Online (ASO). We sincerely hope that you will enjoy using our
            service for years to come. The following Terms & Conditions are necessary to allow us to
            provide you with the best possible service.</p>
        <p>Please read the details set out below carefully before registering with Agility Shows Online</p>
        <p>These Terms are to be read by you together with any terms, conditions and disclaimers
            provided in the pages of the Site, in the event of any conflict, the Terms, Conditions and
            Disclaimers provided in the page of the Site shall prevail over these Terms.</p>
        <p>The information, material and content provided in the pages of the Site may be changed at
            any time without notice. Changes may be made to the Terms at any time without notice by
            updating this posting. You agree to review the Terms regularly and your continued access to
            or use of the Site will mean that you agree to any changes.</p>
        <h5 class="text-primary">Users</h5>
        <p>Each household should have its own separate registration and e-mail address. You are
            wholly responsible for use of the Site by any person using your email address and password
            and you must ensure that any such person complies with these Terms. You are responsible
            for keeping your password safe. Your password can be changed at anytime under your
            Profile after logging into the site. It is suggested this is changed regularly</p>
        <h5 class="text-primary">Registration</h5>
        <p>In order to use Agility Shows Online (ASO), you must register and agree to be bound by these
            Terms & Conditions ("Agreement"). To have the ability to access our website and services, you
            must obtain access to the World Wide Web. In addition, it is your responsibility to find the
            equipment (i.e. computer, modem, and/or other devices) necessary to make such a connection to
            the Internet.</p>
        <p>Once you complete the registration process with Agility Shows Online (ASO), you will receive a
            confirmation e-mail advising how to pay and your unique Payment Reference. You are solely
            responsible for maintaining the details of your account, and are fully accountable for all activities
            that occur under your account. </p>
        <p>In consideration of your use of Agility Shows Online (ASO), you agree to: (1) provide accurate,
            current and complete information about yourself and your dogs as prompted by the registration
            form and (2) maintain and promptly update your personal contact information to keep it current
            and complete. If you provide any information that is untrue, inaccurate, inappropriate or not
            current, or if Agility Shows Online (ASO), has reasonable grounds to suspect that such
            information is untrue, inaccurate, inappropriate or not current, Agility Shows Online (ASO) has the
            right to disable or terminate your account and refuse any and all current or future use of the site.</p>
        <p>You agree that you shall only use the Services and content in a manner that complies with all
            applicable laws in the jurisdictions in which you use the Services and Content, including, but
            not limited to, applicable restrictions concerning copyright and other intellectual property
            rights. Except as specifically authorized herein, you may not: (i) permit other individuals to
            use the Content and Services; (ii) modify, translate, distribute or create derivative works of
            the Content or the Services; (iii) copy or redistribute the Content; (iv) rent, lease, transfer, or
            otherwise transfer rights to the Content or the Services; (v) remove any proprietary notices or
            labels on the Content or Service; and (vi) add to, alter, delete from, or otherwise modify the
            Content.</p>
        <h5 class="text-primary">Your Account</h5>
        <p>Payments in to your Account with Agility Shows Online, can be undertaken in a variety of ways.
            Whichever method is chosen, your unique Payment Reference should always be quoted to enable us to
            track payments and update your account.</p>
            <ul class="list ms-5 mb-3">
                <li>Payment of cash/cheque over the counter at any branch of HSBC</li>
                <li>Internet or telephone banking transfer </li>
                <li>Credit/Debit card payment through SagePay</li>
                <li>Set up a standing order </li>
            </ul>            
        <p>Agility Shows Online Limited banking details are Sort Code 40-39-11, Account number 02305666.
            Please note that it may take 5 complete working days for payments to clear, and hence show as
            available funds on your account.</p>
        <p class="fw-heavy">If cleared funds are not in your account within 5 days of closing date, Agility Shows Online,
            reserve the right to cancel your entries.</p>
        <p>Funds may be requested back from your Agility Shows Online account to the card used for payment or
            a nominated account on submission of the sort code and account number of that account. Funds will be
            transferred online and will incur a handling fee.</p>
        <h5 class="text-primary">Entering a Show</h5>
        <p>By entering a show via Agility Shows Online (ASO), you are entering in to a contract with the organisers
            of the Show in question, and you should note that it may not be possible to cancel an entry, as all
            cancellations are in line with the policy of the particular club involved, Agility Shows Online (ASO)
            Terms & Conditions notwithstanding.
            </p>
        <p>Agility Shows Online (ASO) charges a 75p administration fee for entering a show through its website.</p>
        <p>Agility Shows Online (ASO) also reserve the right to charge an administration fee for cancellations/amendments
            that require intervention by us.
            </p>
        <h5 class="text-primary">Non-Commercial Use</h5>
        <p>The Site is for the personal use of individual Members only and may not be used in connection with any
            commercial endeavours.
            Organizations, companies, and/or businesses may not become Members and should not use the
            Service or the Site for any purpose, other than with prior permission from Agility Shows Online Ltd.</p>
        <h5 class="text-primary">De-Registration</h5>
        <p>Agility Shows Online (ASO), reserves the right to cancel or terminate an individual's account if there is
            speculation of misconduct or similar behaviours.
            Should you breach this Agreement, Agility Shows Online (ASO), will cancel your registration and suspend your right of access.</p>
        <p>Agility Shows Online (ASO), also reserves the right to delete registrations that have not been used for
            over a year.
            You can request to be de-registered at any time and any funds in your ASO Account will be returned as
            detailed in ‘Your Account’ above. </p>
        <h5 class="text-primary">Modifications to Service</h5>
        <p>These Terms & Conditions were last modified on 2
            nd November 2020. At any time and without prior
            notice, Agility Shows Online (ASO), shall have the right, in its sole discretion, to modify, add or remove
            terms of these Terms & Conditions, without notifying our customers of such modifications, additions or
            removals, and all such changes shall be effective immediately. Your continued use of Agility Shows
            Online (ASO) services following our posting of any such change on our site will constitute binding
            acceptance of such change. You agree that Agility Shows Online (ASO) shall not be liable to you or to
            any third party for any modification, suspension or discontinuance of the service.</p>
        <h5 class="text-primary">Communication</h5>
        <p>As a part of the service Agility Shows Online (ASO) provides to our registered users, we communicate
            via e-mail and other means to inform you of enhancements or changes in services, or other relevant
            information targeted to your interests. Details of these communication efforts are noted within the
            Privacy Policy. Users have the option to remove themselves from various e-mail communications by
            following the conditions listed within the message or by following the procedures as noted in the Privacy
            Policy. However, even though users may request removal from the distribution of certain e-mails, Agility
            Shows Online (ASO), has the right to send messages from time to time that we believe would interest
            them (e.g. cancellation of a show). Agility Shows Online (ASO), reserves the right at any time to modify
            or discontinue, temporarily or permanently, the service (or any part thereof) with or without notice.</p>
        <h5 class="text-primary">Privacy Policy</h5>
        <p>You should review the other Agility Shows Online (ASO) policies to better comprehend our practices
            and how we govern your visit to our website.
            Registration data and certain other information about you are subject to our Privacy Policy which can be
            viewed from the link at the bottom of every page on the website. </p>
        <h5 class="text-primary">Third Party Links</h5>
        <p>This website contains links to Internet websites maintained by third parties. Agility Shows Online (ASO)
            does not operate or control in any respect any information, products, or services on such third-party
            sites. Third-party links do not constitute any endorsement by Agility Shows Online (ASO) and/or its
            affiliates. User assumes sole responsibility for use of these third-party links. The disclaimers set forth
            herein are equally applicable to such third-party sites</p>
        <h5 class="text-primary">Copyright and Trademarks</h5>
        <p>The content included on this website, including, but not limited to the Agility Shows Online (ASO)
            reports, text, graphics, logos, icons, and images is the exclusive property of Agility Shows Online (ASO)
            and other respective owners that have granted Agility Shows Online (ASO) the right and license to use
            such property and is protected by international copyright laws. All trademarks, service marks, and trade
            names are proprietary to Agility Shows Online (ASO), or other respective owners that have granted
            Agility Shows Online (ASO) the right and license to use such marks. The Agility Shows Online (ASO)
            website contains copyrighted material, including, but not limited to: trademarks and other proprietary
            information, content, text, and graphics. Except as otherwise expressly permitted under copyright law,
            users may not copy, publish, redistribute, modify, transmit, display, sell, create derivative works, or in
            any way exploit the content or any portion of the Agility Shows Online (ASO) website without the
            express permission of the organization. </p>
        <h5 class="text-primary">Disclaimers and Warranties</h5>
        <p>Agility Shows Online (ASO) any of our affiliates, nor any of our or their respective licensors, licensees,
            service providers or suppliers warrant that this website or any function contained in this website will be
            uninterrupted or error-free, that defects will be corrected, or that this website or the servers that make
            this website available are free of viruses or other harmful components.</p>
        <p>Neither Agility Shows Online (ASO), nor any of our affiliates, nor any of our or their respective licensors,
            licensees, service providers or suppliers warrant or make any representations regarding the use or the
            results of the use of the products, offerings, content and materials (including, without limitation, the feebased products) in this website in terms of their correctness, accuracy, reliability, or otherwise. Further,
            please note that no advice or information, obtained by you from our personnel or through this website
            shall create any warranty not expressly provided for in this agreement.</p>
        <h5 class="text-primary">Internet eMail</h5>
        <p>Messages sent over the internet cannot be guaranteed to be completely secure as they are subject to
            possible interception or loss or possible alteration. We are not responsible for them and will not be
            liable to you or anyone else for any damages or otherwise in connection with a message sent by you to
            us or any message sent by us to you over the internet.</p>
        <h5 class="text-primary">Trademarks and Copyright</h5>
        <p>Copyright in the page screens, information and material in their arrangement included in this Site is
            owned by us unless otherwise noted. You may imprint, copy, download or temporarily store extracts
            from our Site for your personal information or when you use our products and services. You must not
            alter anything. Any other use is prohibited unless you first get our written permission. In particular no
            one may use a part of our Site on any other website, or link any other website to our Site, without our
            prior written permission.</p>
        <h5 class="text-primary">Linked Internet Sites</h5>
        <p>We prohibit caching, unauthorised hypertext links to the site and the framing of any Site content. We
            reserve the right to disable any unauthorised links or frames and specifically disclaim any responsibility
            for Site content available on any other internet sites linked to the Site. Access to any other internet sites
            linked to the Site is at your risk.</p>
        <h5 class="text-primary">Limitation of Liability</h5>
        <p>Whilst we have taken reasonable steps to ensure the accuracy, correctness and completeness of the
            information contained on the Site, information is provided on an ‘as is’, ‘as available’ basis and we do
            not give or make any warranty or representation of any kind, whether express or implied. The use of
            the Site is at your sole risk. We shall not be liable for any loss or damage whatsoever and howsoever
            arising as a result of your use of or reliance on the information contained on the Site to the maximum
            extent permitted by law.</p>
        <p>We cannot guarantee that your entry is correct, nor offer any refunds if this is not the case.</p>
        <p>We do not represent or warrant that the Site will be available and meet your requirements, that access
            will be uninterrupted, that there will be no delays, failures, errors or omissions or loss of transmitted
            information, that no viruses or other contaminating or destructive properties will be transmitted or that no
            damage will occur to your computer system. You have sole responsibility for adequate protection and
            back up of data and/or equipment and for undertaking reasonable and appropriate precautions to scan
            for computer viruses or other destructive properties.</p>
        <p>You expressly understand and agree that Agility Shows Online (ASO) and our affiliates shall not be
            liable for any direct, indirect, incidental, special, consequential, exemplary or punitive damages, or any
            other damages whatsoever, including but not limited to, damages for loss of profits, goodwill, use, data
            or other intangible losses (even if we have been advised of the possibility of such damages), arising out
            of, or resulting from, (a) the use or the inability to use this website; (b) the use of any content or other
            material on this website or any website or websites linked to this website; (c) the cost of procurement of
            substitute goods and services resulting from any goods, data, information or services purchased or
            obtained or messages received or transactions entered into through or from this website; (d)
            unauthorized access to or alteration of your transmissions or data; (e) statements or conduct of any
            third party on our website, or (f) any other matter relating to this website. In no event shall our total
            liability to you for all damages, losses, and causes of action (whether in contract, tort (including, but not
            limited to, negligence), or otherwise) exceed the amount paid by you, if any, for accessing this website.
            If you are dissatisfied with any portion of our website, or with any of provision of this agreement, your
            sole and exclusive remedy is the discontinuation of your use of this website.</p>
        <h5 class="text-primary">Indemnity</h5>
        <p>You agree to indemnify and hold Agility Shows Online (ASO), its subsidiaries, affiliates, officers, agents,
            and other partners and employees, harmless from any loss, liability, claim, or demand, including
            reasonable lawyer's fees, made by any third party due to or arising out of your use of the Service in
            violation of this Agreement and/or arising from a breach of these Terms & Conditions and/or any breach
            of your representations and warranties set forth above.</p>
        <p>All questions relating to this website's validity, interpretations, performance and enforcement, including
            the Terms & Conditions, shall be governed by and construed in accordance with the law. Any action
            relating to this website's validity, interpretations, performance and enforcement, including the Terms &
            Conditions, must be brought in the UK, and you irrevocably consent to the jurisdiction of such courts.
            Our failure to enforce your strict performance of any provision of these Terms & Conditions will not
            constitute a waiver of our right to subsequently enforce such provision or any other provision of this
            policy. Any waiver, amendment or other modification of any provision of these Terms & Conditions will
            be effective only if in writing and signed by an authorized representative of Agility Shows Online (ASO)</p>
        <p>Agility Shows Online (ASO) will not be responsible or liable to you for any delay or failure to perform
            under these Terms & Conditions if such delay or failure results from fire, explosion, labour dispute,
            earthquake, casualty or accident, lack or failure of transportation facilities and/or services, lack or failure
            of telecommunications facilities and/or services including Internet services, epidemic, flood, drought, or
            by reason of war, civil commotion, blockade or embargo, act of God, any inability to obtain any requisite
            license, permit or authorization, or by reason of any law, proclamation, regulation, ordinance, demand or
            requirement of any government or by reason of any other cause whatsoever, whether similar or
            dissimilar to those enumerated, beyond the reasonable control of Agility Shows Online (ASO).</p>
        <h5 class="text-primary">Special Admonitions for International Use</h5>
        <p>Recognizing the global nature of agility, you agree to comply with the show rules & regulations
            regarding entry. Payment through SagePay is recommended.</p>
        <h5 class="text-primary">Miscellaneous Terms</h5>
        <p>Agility Shows Online (ASO) may assign our rights and obligations under this Agreement. This
            Agreement will inure to the benefit of our successors, assigns and licensees. The failure of either party
            to insist upon or enforce the strict performance of the other party with respect to any provision of this
            Agreement, or to exercise any right under this Agreement, will not be construed as a waiver or
            relinquishment to any extent of such party's right to assert or rely upon any such provision or right in that
            or any other instance; rather, the same will be and remain in full force and effect.</p>
        <h5 class="text-primary">Entire Agreement</h5>
        <p>These Terms & Conditions represent the complete and entire agreement between Agility Shows Online
            (ASO) and you and supersede all prior and contemporaneous agreements of the parties relating to the
            subject matter hereof. If any provision of this Agreement is held invalid, the remainder of this Agreement
            shall continue in full force and effect. You acknowledge that you have read these Terms & Conditions
            and understand and agree to all of them in their entirety. You have independently evaluated the
            desirability of using Agility Shows Online (ASO), and are not relying on any representation, guarantee,
            or statement other than as set forth in these Terms & Conditions.</p>
        <p>IMPORTANT: These Terms & Conditions of Service for Agility Shows Online (ASO) Services
            ("Agreement") is a legal agreement between you and Agility Shows Online (ASO) Ltd. By using or
            accepting the Services, you agree to be bound by the terms of this Agreement. If you do not agree to
            the Terms of this Agreement, do not use these Services. You agree that your use of the services
            acknowledges that you have read this Agreement, understand it, and agree to be bound by its Terms
            and Conditions.</p>
        <h5 class="text-primary">Governing Law</h5>
        <p>These Terms are governed by and interpreted in accordance with the laws of England & Wales and the
            courts of England and Wales will have non-exclusive jurisdiction in respect of any dispute which may
            arise out of the subject matter of these Terms or the use of the Site.</p> 
      </div>
      <div>
        <button class="btn btn-success me-auto" @click="goback">Back</button>
        </div>
    </div>
  </section>
</template>
  
  <script>
import { useRouter } from "vue-router";
export default {
    name: "Terms",
    setup() {
      const router = useRouter();
      const goback = () => {
        router.go(-1);
      };
      return { goback };
    },
  };

  </script>
  
  <style>
  </style>
  