import { ref, watchEffect } from 'vue'

// firebase imports
import { db } from '../firebase/config'
import { collection, query, where, onSnapshot } from 'firebase/firestore'

// Return a collection of my documents from Firestore and subscribe to updates
const getMyCollection = (c,userId) => {
  const documents = ref(null)
  // query reference collection c, where field f, is equal to value v
  const q = query(collection(db, c), where('owner', "==", userId))
  const unsub = onSnapshot(q, snapshot => {
    let results = []
    snapshot.docs.forEach(doc => {
      results.push({ ...doc.data(), id: doc.id })
    })
    
    // update values
    documents.value = results
  })

  watchEffect((onInvalidate) => {
    onInvalidate(() => unsub());
  });

  return { documents }
}

export default getMyCollection
