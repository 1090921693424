<template>
  <!-- address form -->
  <section id="register">
    <div class="container-lg">
      
      <div class="text-center">
        <p class="lead">Please add your postal addrress</p>
      </div>
      <div class="text-center">
        <p class="text-primary small">A full postal address is required before you can enter a show, <br>a mobile number is required to securely access online ring cards and live scoring.</p>
      </div>
      <div class="row justify-content-center my-5">
        <Address />
      </div>
    </div>
  </section> 
</template>

<script>
import Address from '../account/Address.vue'

export default {
    name: "RegisterDetails",
    setup() {

    },
    components: { Address }
}
</script>

<style>
.small {
  font-size: 0.8rem;
}
</style>