<template>
  <!-- navbar -->
  <nav class="navbar navbar-expand-md navbar-light pt-1 pb-4">
    <div class="container-xxl border-bottom border-danger border-3">
      <!-- navbar brand / title -->
      <router-link class="navbar-brand" to="/">
        <span class="tt" data-bs-placement="bottom" title="Agility Shows Online - Always Evolving">
          <img src="../assets/ASO_medium_logo.png" class="img-fluid" alt="logo">
        </span>
      </router-link>
      <!-- toggle button for mobile nav -->
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main-nav" aria-controls="main-nav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- navbar links -->
      <div class="collapse navbar-collapse justify-content-end align-center" id="main-nav">
        <ul class="navbar-nav ">
          
          <li class="nav-item" v-if="user">
            <router-link class="nav-link" :to="{name:'settings'}">
              <!-- Display only on small or less (collapsed) -->
              <span class="d-block d-md-none" data-bs-toggle="collapse" data-bs-target="#main-nav">
                <i class="bi bi-gear"></i> Dogs, handlers and grades
              </span>
              <!-- Display only on medium and above (top bar) -->
              <span class="d-none d-md-block" >Dogs</span>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link class="nav-link" :to="{name:'shows'}">
              <!-- Display only on small or less (collapsed) -->
              <span class="d-block d-md-none" data-bs-toggle="collapse" data-bs-target="#main-nav">
                <i class="bi bi-calendar2-check"></i> Shows to enter
              </span>
              <!-- Display only on medium and above (top bar) -->
              <span class="d-none d-md-block" >Shows</span>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link class="nav-link" :to="{name:'results'}">
              <!-- Display only on small or less (collapsed) -->
              <span class="d-block d-md-none" data-bs-toggle="collapse" data-bs-target="#main-nav">
                <i class="bi bi-award"></i> Results of previous shows
              </span>
              <!-- Display only on medium and above (top bar) -->
              <span class="d-none d-md-block" >Results</span>
            </router-link>
          </li>
          
          <li class="nav-item d-md-inline">
            <router-link class="nav-link" :to="{name:'contact'}">
              <!-- Display only on small or less (collapsed) -->
              <span class="d-block d-md-none" data-bs-toggle="collapse" data-bs-target="#main-nav">
                <i class="bi bi-chat-left-dots"></i> Get in Touch
              </span>
            </router-link>
          </li>

          <li class="nav-item" v-if="!user">
            <router-link class="nav-link" :to="{name:'register'}">
              <!-- Display only on small or less (collapsed) -->
              <span class="d-block d-md-none" data-bs-toggle="collapse" data-bs-target="#main-nav">
                <i class="bi bi-person-add"></i> Create an account
              </span>
              <!-- Display only on medium and above (top bar) -->
              <span class="d-none d-md-block" >Register</span>
            </router-link>
          </li>
          
          <li class="nav-item" v-if="!user">
            <router-link class="nav-link" :to="{name:'login'}">
              <!-- Display only on small or less (collapsed) -->
              <span class="d-block d-md-none" data-bs-toggle="collapse" data-bs-target="#main-nav">
                <i class="bi bi-box-arrow-in-right"></i> Login for a personalised view
              </span>
              <!-- Display only on medium and above (top bar) -->
              <span class="nav-highlight d-none d-md-block" >Login</span>
            </router-link>
          </li>
          
          <li class="nav-item" v-if="user">
            <span class="nav-link" @click="logout">
              <!-- Display only on small or less (collapsed) -->
              <span class="d-block d-md-none" data-bs-toggle="collapse" data-bs-target="#main-nav">
                <i class="bi bi-box-arrow-left"></i> Logout
              </span>
              <!-- Display only on medium and above (top bar) -->
              <span class="nav-lowlight d-none d-md-block" >Logout</span>
            </span>
          </li>

          <li class="nav-item" v-if="user">
            <router-link class="nav-link" :to="{name:'settings'}">
              <!-- Display only on medium and above (top bar) -->
              <span class="d-none d-md-block tt" data-bs-placement="left" :title="user.email">
                <i class="bi bi-person-square"></i>
              </span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>    
</template>

<script>
import { useRouter } from 'vue-router'
import {watchEffect } from 'vue'
import getUser from '@/composables/getUser'
import { auth } from '@/firebase/config'
import { signOut } from 'firebase/auth'


export default {
  name: 'Header',
  setup() {
    const { user } = getUser()
    const router = useRouter()
  
    const logout = async () => {
      try {
        await signOut(auth)
      } catch (err) {
        console.log(err.message)
      }
    }
    // Add a worker to watch the state of login and do something
    watchEffect(() => {
      if (!user.value) {
        // except this causes a loop so we need to be a bit more clever
        //router.push('/')
      }
    })
    //
    return { user, logout }
  }
}
</script>

<style>  
    @media (max-width: 600px) {  
        .table-responsive {
            font-size:0.6em !important;
        }
    } 
    .nav-item {
      font-size:1em;
    }
    .nav-link {
      padding-top: 0px !important;
      padding-bottom: 5px !important;
      cursor: pointer;
    }
    .nav-lowlight {
      padding: 0px 5px 5px 5px !important;
      border: 1px solid #aaaaaa;
      border-radius: 10px;
    }
    .nav-highlight {
      padding: 0px 5px 5px 5px !important;
      border: 1px solid #888888;
      color : white;
      background-color:#aaaaaa;
      border-radius: 10px;
    }    
</style>
