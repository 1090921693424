<template>
    <footer class="footer">
        <div class="container-xxl border-top border-danger border-2 mt-4 px-0">
          <div class="mx-0 px-0" :style="{ backgroundImage: 'url(' + require(`../assets/footer_dog.png`) + ')', height: '700px' }">
            <div class="container p-4">
                <div class="row">
                    <div class="col-lg-3 col-md-6">
                        <ul class="list-unstyled ms-3 mb-3">
                        <li><router-link :to="{name:'process'}" class="text-white text-decoration-none">Show processing</router-link></li>
                        <li><router-link :to="{name:'contact'}" class="text-white text-decoration-none">Contact Us</router-link></li>
                        <li><router-link :to="{name:'tandc'}" class="text-white text-decoration-none">ASO Terms and Conditions</router-link></li>
                        <li><router-link :to="{name:'privacy'}" class="text-white text-decoration-none">ASO Privacy Policy</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="p-3 text-white">
                © 2023 Copyright:&nbsp;
                <router-link class="text-white text-decoration-none" :to="{name:'home'}"> agilityshows.online</router-link>
                </div>
            </div>

            </div>
        </div>
        </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>