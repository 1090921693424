<template>
<!-- Main panel content-->
  <section>  
    <div class="float-end">
      <span class="tt link" data-bs-placement="bottom" title="Add another handler">
      <button type="button" class="btn btn-light" data-bs-toggle="modal" data-bs-target="#handlerModal" data-bs-style="add">
        <h3><i class="bi bi-plus-circle"></i></h3>
      </button>
      </span>
    </div>
    <ul class="list-unstyled">
      <div v-for="handler in handlers" :key="handler.id">
      <li class="list-group-item d-flex align-items-start">
        <span class="tt link" data-bs-placement="bottom" title="Remove this handler from your list.">
          <i class="bi bi-trash" data-bs-toggle="modal" data-bs-target="#handlerModal" data-bs-style="delete" :data-bs-id="handler.id" ></i>&nbsp;
        </span>
        <span class="tt link" data-bs-placement="bottom" title="Edit this handler.">
          <i class="bi bi-pencil-square" data-bs-toggle="modal" data-bs-target="#handlerModal" data-bs-style="edit" :data-bs-id="handler.id" ></i>&nbsp;
        </span>
        <small>{{handler.name}}&nbsp;</small>
        <small  class="text-muted">&nbsp;({{getAgeGroup(handler.dob)}})</small >
      </li>
    </div>
    </ul>

  </section>

<!-- pop up modal for add/edit/delete -->
<div class="modal fade" ref="handlerModal" id="handlerModal" tabindex="-1" aria-labelledby="handlerModalTitle" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="handlerModalTitle" ref="handlerModalTitle">Handler</h5>
        <button id="dismissHandlerModal" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" ref="handlerSimpleContent">
      <span ref="handlerModalMessage"></span>
      </div>
      <form ref="handlerForm" @submit.prevent="processHandler">
        <div ref="handlerFormContent" class="modal-body">
          <input type="text" class="form-control form-control-sm" v-model="fullName" placeholder="Enter the full name">
          <div class="feedback" v-if="fullNameError">{{ fullNameError }}</div>
          <br>
          <p class="small">If you would like this person to qualify for any age restricted classes we need to confirm their date of birth please...</p>
          <input type="date" class="form-control form-control-sm" v-model="dob">
          <input type="hidden" ref="handlerFormStyle" value="">
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-danger" ref="handlerDeleteBtn" data-bs-dismiss="modal" @click.prevent="deleteHandler">Remove</button>
          <button type="submit" class="btn btn-success" ref="handlerSaveBtn">Save</button>
          <button type="submit" class="btn btn-success" ref="handlerUpdateBtn">Update</button>
        </div>
      </form>
    </div>
  </div>
</div>

</template>    

<script>
import { ref, onMounted } from "vue";
import dayjs from 'dayjs'
import getUser from '@/composables/getUser'
import getMyCollection from '@/composables/getMyCollection'
import { db } from '@/firebase/config'
import { addDoc, setDoc, deleteDoc, doc, collection } from "@firebase/firestore"

export default {
  name: "Handlers",
  setup() {
    // Get the existing data from Firestore as we have the id
    const { user } = getUser()
    const userId = user.value.uid
    const colRef = collection(db, 'handlers')
    // Form data refs
    const fullName = ref('')
    const fullNameError = ref('')
    const dob = ref('')
    const dobError = ref('')
    // Modal refs for modifying the modal display
    const handlerModal = ref(null)
    const handlerForm = ref(null)
    const handlerFormStyle = ref(null)
    const handlerSimpleContent = ref(null)
    const handlerFormContent = ref(null)
    const handlerModalMessage = ref(null)
    const handlerModalTitle = ref(null)
    const handlerModalMsg = ref(null)
    const handlerDeleteBtn = ref(null)
    const handlerUpdateBtn = ref(null)
    const handlerSaveBtn = ref(null)
 
    // get list of this account's handlers if they already exist       
    // We use the getMyCollection composable to fetch the data from firestore
    const { documents: handlers }  = getMyCollection('handlers',userId)

    // Function to return 'Adult', 'Senior' or 'Junior' based on the handler's age
    const getAgeGroup = (dob) => {
      //if dob not set assume about 30 years old
      if (!dob) return 'Unknown age'
      const birthday = dayjs(dob)
      const today = dayjs()
      const age = today.year() - birthday.year()
      if (age < 18) {
        return 'Junior'
      } else if (age < 65) {
        return 'Adult'
      } else {
        return 'Senior'
      }
    }

    const validateForm = ()=> {
      fullNameError.value = ''
      if (fullName.value.length < 5) {
        fullNameError.value = 'Please enter a valid full name'
      }

      if (fullNameError.value ) {
        return false
      } else {
        return true
      }
    }

    const validateNonDuplicate = () => {
      // Check to see if this name is already in the handlers list
      if (handlers.value.find(handler => handler.name == fullName.value) ) {
        fullNameError.value = 'This handler is already in your list'
      }
      if (fullNameError.value ) {
        return false
      } else {
        return true
      }
    }

    const processHandler = (event) => {
      const handler = {
          name: fullName.value,
          owner: userId,
          dob: dob.value
        }
      if (validateForm()) {
        if ((handlerFormStyle.value.value == 'add') && validateNonDuplicate()) {
          try {
            addDoc(colRef, handler)
          } catch (err) {
            console.log(err.message)
          }
        } else if (handlerFormStyle.value.value == 'edit') {
          try {
            setDoc(doc(db, 'handlers', event.srcElement.id), handler)
          } catch (err) {
            console.log(err.message)
          }
        }
        let dismiss = document.querySelector('#dismissHandlerModal')
        dismiss.click()
      }
    } 

    const deleteHandler = (event) => {
      // delete the handler with id==event.srcElement.name from firestore
      if(event.srcElement.id) {
        deleteDoc(doc(db, 'handlers', event.srcElement.id))
      }
    }

    onMounted(() => { 
      // Now we are mounted add an event listener to the modal reveal event
      handlerModal.value.addEventListener('show.bs.modal', function (event) {
        // Query the button that triggered the modal for why it was triggered
        var button = event.relatedTarget
        var formStyle = button.getAttribute('data-bs-style')
        handlerFormStyle.value.value = formStyle
         var visible = 'block'
         var hidden = 'none'
         if (formStyle == 'delete') {
           handlerFormContent.value.style.display = hidden
           handlerSimpleContent.value.style.display = visible
           handlerModalTitle.value.textContent = 'Are you sure?'
           var handlerName = handlers.value.find(handler => handler.id == button.getAttribute('data-bs-id')).name
           handlerModalMessage.value.textContent = 'Remove handler ' + handlerName + '?'
           // Then update the delete button to point to the correct handler id
           handlerDeleteBtn.value.setAttribute('id', button.getAttribute('data-bs-id'))        
           handlerDeleteBtn.value.style.display = visible
           handlerUpdateBtn.value.style.display = hidden
           handlerSaveBtn.value.style.display = hidden
         } else if (formStyle == 'add') {
           // show the form
           handlerFormContent.value.style.display = visible
           handlerSimpleContent.value.style.display = hidden
           handlerModalTitle.value.textContent = 'Add handler'
           handlerForm.value.reset()
           dob.value = dayjs().subtract(30,'year').format('YYYY-MM-DD')
           handlerDeleteBtn.value.style.display = hidden
           handlerUpdateBtn.value.style.display = hidden
           handlerSaveBtn.value.style.display = visible
         } else if (formStyle == 'edit') {
           handlerFormContent.value.style.display = visible
           handlerSimpleContent.value.style.display = hidden
           handlerModalTitle.value.textContent = 'Edit handler'
           // populate the form with the handler's details
           var handler = handlers.value.find(handler => handler.id == button.getAttribute('data-bs-id'))
           if (handler.name) fullName.value = handler.name
           if (handler.dob) dob.value = handler.dob
           handlerForm.value.setAttribute('id', button.getAttribute('data-bs-id'))
           handlerDeleteBtn.value.style.display = hidden
           handlerUpdateBtn.value.style.display = visible
           handlerSaveBtn.value.style.display = hidden
         }
       })
    })

    return { handlers, fullName, fullNameError, dob, dobError, deleteHandler, processHandler, getAgeGroup, 
      handlerModal, handlerFormStyle, handlerSimpleContent, handlerFormContent, handlerModalMessage, handlerModalTitle,
      handlerModalMsg, handlerDeleteBtn, handlerUpdateBtn, handlerSaveBtn, handlerForm }
  }
}
</script>

<style>
.link {
  cursor: pointer;
}
.feedback {
    color: red;
    font-size: 0.8rem;
    text-decoration: none;
  }
</style>
