<template>
  <section id="address">
    <div class="container-lg">
      <h2 class="text-center">Welcome to ASO - Next Generation</h2>
    </div>
  </section>


</template>

<script>

export default {
  name: 'Home',

}
</script>
