import { ref } from 'vue'

// Firebase imports
import { auth } from '../firebase/config'
import { onAuthStateChanged } from 'firebase/auth'

// refs
const user = ref(auth.currentUser)

// react to auth changes
onAuthStateChanged(auth, (currentUser) => {
    //console.log('User state changed. Current user is:', currentUser)
    user.value = currentUser
})

const getUser = () => {
    return { user }
}

export default getUser
