<template>
    <div>
      <section>
        <h1>Show Processing from Agility Shows Online</h1>
        <p>Whether you are planning a-</p>
        <ol>
          <li>KC Championship Show</li>
          <li>KC Premier Show</li>
          <li>KC Open Show</li>
          <li>KC Limited Show</li>
          <li>Independent Show</li>
          <li>Winter/Summer League Events</li>
        </ol>
        <p>For any type of class - Standard, Limited, Capped, Pairs, Teams, YKC Qualifiers, Trios and all kinds of Special classes</p>
        <h3>We are here to help you!</h3>
        <p>We have a complete range of options to deal with all of your entry and processing requirements whether you want a complete, partial or DIY service.
          We offer a paperless tablet/laptop electronic system with full online scoring and results or full paper system.
          If you have any questions, or for a detailed quote, then do please <router-link to="/contact">Contact Us</router-link></p>
      </section>
    </div>
  </template>
  
  <script>
  export default {
    name: "Process",
  };
  </script>
  
  <style>
  </style>
  