import { createRouter, createWebHistory } from 'vue-router'
import  { auth } from '../firebase/config'
import Home from '../views/Home.vue'
import Shows from '../views/Shows.vue'
import Results from '../views/Results.vue'
import Contact from '../views/static/Contact.vue'
import Process from '../views/static/Process.vue'
import Register from '../views/user/Register.vue'
import Login from '../views/user/Login.vue'
import Privacy from '../views/static/Privacy.vue'
import Terms from '../views/static/Terms.vue'
import Dogs from '../views/account/Dogs.vue'
import Settings from '../views/account/Settings.vue'
import RegisterDetails from '../views/user/RegisterDetails.vue'

const requireAuth = (to, from, next) => {
  let user = auth.currentUser
  if (!user) {
    next({name: 'login'})
  } else {
    next()
  }
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/shows',
    name: 'shows',
    component: Shows
  },
  {
    path: '/results',
    name: 'results',
    component: Results
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: '/process',
    name: 'process',
    component: Process
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Privacy
  },
  {
    path: '/tandc',
    name: 'tandc',
    component: Terms
  },
  // Pages above are Public, routes below require authentication
  { 
    path: '/address',
    name: 'address',
    component: RegisterDetails,
    beforeEnter: requireAuth
  },
  {  // ToDo: This is a tab do we need to go here direct?
    path: '/dogs',
    name: 'dogs',
    component: Dogs,
    beforeEnter: requireAuth
  },  
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    beforeEnter: requireAuth
  },    
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
