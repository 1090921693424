<template>
    <section id="address">
        <div class="container-lg">
            <div class="text-center">
                <p class="lead mb-2">Settings</p>
            </div>
              <div class="accordion accordion-flush" id="account">
                <div class="accordion-item">
                    <h4 class="accordion-header" id="dogs">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseDogs" aria-expanded="true" aria-controls="collapseDogs">
                            Dogs
                        </button>
                    </h4>
                    <div id="collapseDogs" class="accordion-collapse collapse show" aria-labelledby="dogs" data-bs-parent="#account">
                        <div class="accordion-body">
                            <Dogs />
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h4 class="accordion-header" id="handlers">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseHandlers" aria-expanded="false" aria-controls="collapseHandlers">
                            Handlers
                        </button>
                    </h4>
                    <div id="collapseHandlers" class="accordion-collapse collapse" aria-labelledby="handlers" data-bs-parent="#account">
                        <div class="accordion-body">
                            <Handlers />
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h4 class="accordion-header" id="contactPane">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseContact" aria-expanded="false" aria-controls="collapseContact">
                            Contact information
                        </button>
                    </h4>
                    <div id="collapseContact" class="accordion-collapse collapse" aria-labelledby="contactPane" data-bs-parent="#account">
                        <div class="accordion-body">
                            <Address />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
  
<script>
import { ref } from 'vue'
import Dogs from './Dogs.vue'
import Address from './Address.vue'
import Handlers from './Handlers.vue'

export default {
    name: "Account",
    setup() {
        const activeItem = ref("dogs");
        const isActive = (menuItem) => {
            return activeItem.value === menuItem;
        };
        const setActive = (menuItem) => {
            activeItem.value = menuItem;
        };
        return { activeItem, isActive, setActive };
    },
    components: { Dogs, Address, Handlers }
}
</script>

<style>
</style>
  