<template>
  <!-- contact form -->
  <section id="contact">
      <div class="container-lg">
        
        <div class="text-center">
          <p class="lead">Questions to ask? Fill out the form to talk to us directly...</p>
        </div>
        <div class="row justify-content-center my-5">
          <div class="col-lg-6">
            
            <form @submit.prevent="handleSubmit" >
              <label for="email" class="form-label">Email address:</label>
              <div class="feedback" v-if="emailError">{{ emailError }}</div>
              <div class="input-group mb-4">
                <span class="input-group-text">
                  <i class="bi bi-envelope-fill text-secondary"></i>
                </span>
                <input type="text" id="email" class="form-control" placeholder="e.g. joan@example.com" v-model="email"/>
                <!-- tooltip -->
                <span class="input-group-text">
                  <span class="tt" data-bs-placement="bottom" title="Enter an email address we can reply to.">
                    <i class="bi bi-question-circle text-muted"></i>
                  </span>
                </span>
              </div>
              <label for="name" class="form-label">Name:</label>
              <div class="feedback" v-if="nameError">{{ nameError }}</div>
              <div class="mb-4 input-group">
                <span class="input-group-text">
                  <i class="bi bi-person-fill text-secondary"></i>
                </span>
                <input type="text" id="name" class="form-control" placeholder="e.g. Joan Bloggs" v-model="name" />
                <!-- tooltip -->
                <span class="input-group-text">
                  <span class="tt" data-bs-placement="bottom" title="Please use your full name...">
                    <i class="bi bi-question-circle text-muted"></i>
                  </span>
                </span>
              </div>
              <label for="subject" class="form-label">What can we help you with?</label>
              <div class="feedback" v-if="subjectError">{{ subjectError }}</div>
              <div class="mb-4 input-group">
                <span class="input-group-text">
                  <i class="bi bi-chat-right-dots-fill text-secondary"></i>
                </span>
                <select class="form-select" id="subject"  v-model="subject">
                  <option value="" disabled selected> </option>
                  <option value="General query">General query</option>
                  <option  value="Registration problems">Registration problems</option>
                  <option  value="Account Payment">Account Payment</option>
                  <option  value="Card Payment Problems">Card Payment Problems</option>
                  <option value="Show query">Show query</option>
                  <option  value="Login problems">Login problems</option>
                  <option  value="Updating details">Updating details</option>
                  <option  value="Amending show entry">Amending show entry</option>
                  <option value="Cancel show entry">Cancel show entry</option>
                  <option  value="Remove a dog">Remove a dog</option>
                  <option  value="Show Diary">Show Diary</option>
                  <option  value="Our show on ASO">Our show on ASO</option>
                  <option  value="Show Processing">Show Processing</option>
                  <option value="Shop query">Shop query</option>
                </select>
              </div>
              <div class="mb-4 mt-5 form-floating">
                <div class="feedback" v-if="queryError">{{ queryError }}</div>
                <textarea class="form-control" id="query" style="height: 140px" placeholder="query" v-model="query"></textarea>
                <label for="query">Your question...</label>
              </div>
              <div class="mb-4 text-center">
                <button type="submit" class="btn btn-secondary">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import { ref } from 'vue'
  import { useRouter } from 'vue-router'

  export default {
    name: "Contact",
    setup() {
      const router = useRouter()
      const email = ref('')
      const name = ref('')
      const subject = ref('')
      const query = ref('')
      const emailError = ref('')
      const nameError = ref('')
      const subjectError = ref('')
      const queryError = ref('')
      const handleSubmit = () => {
        // validate the form
        const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        // Test the email address        
        emailError.value = emailRegex.test(email.value) ? '':'Please enter a valid email address'
        nameError.value = name.value === '' ? 'Please add your name' : ''
        subjectError.value = subject.value === '' ? 'Please select a subject' : ''
        queryError.value = query.value === '' ? '... and enter your question in the box' : ''
        // if all fields are valid, submit the form
        if (emailError.value === '' && nameError.value === '' && subjectError.value === '' && queryError.value === '') {
          // !!!!!! do the work here to submit the form !!!!!!!
          alert('Your query has been submitted. We will get back to you as soon as possible.')
          router.push('/')
        }
      }
      return {email, name, subject, query, emailError, nameError, subjectError, queryError, handleSubmit}
    },
  };
  const tooltips = document.querySelectorAll('.tt')
    tooltips.forEach(t => {
      new bootstrap.Tooltip(t)
    })
  </script>
  
  <style>
  .feedback {
    display: block;
    color: red;
    font-size: 0.8rem;
  }
  </style>
  