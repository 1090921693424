<template>
    <div>
      <h1>Results</h1>
    </div>
  </template>
  
  <script>
  export default {
    name: "Results",
  };
  </script>
  
  <style>
  </style>
  