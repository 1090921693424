// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDgIV2ysEtiWr5_DfmrtqWFq0DnUDFKK4E",
  authDomain: "agilitum-7fc4b.firebaseapp.com",
  projectId: "agilitum-7fc4b",
  storageBucket: "agilitum-7fc4b.appspot.com",
  messagingSenderId: "840119443907",
  appId: "1:840119443907:web:19b1734a9485186d0edd76",
  measurementId: "G-XV25X06FV7"
};

// Initialize Firebase
initializeApp(firebaseConfig);
const db = getFirestore();
const auth = getAuth();

export {db, auth}