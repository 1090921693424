<template>
    <form @submit.prevent="handleSubmit">

      <div class="row justify-content-center g-3 mb-1">
        <div class="col-md-6">
          <!-- <label for="streetaddress" class="form-label">Postal Address:</label> -->
          <div class="feedback" v-if="streetaddressError">{{ streetaddressError }}</div>
          <input type="text" id="streetaddress" class="form-control form-control-sm" v-model="streetaddress" placeholder="Number and Street"/>
        </div>
      </div>

      <div class="row justify-content-center g-3 mb-1">
        <div class="col-md-6">
          <input type="text" id="locality" class="form-control form-control-sm" v-model="locality" placeholder="Locality"/>
        </div>
      </div>

      <div class="row justify-content-center g-3 mb-1">
        <div class="col-md-6">
          <div class="feedback" v-if="postaltownError">{{ postaltownError }}</div>
          <input type="text" id="postaltown" class="form-control form-control-sm" v-model="postaltown" placeholder="Postal Town"/>
        </div>            
      </div> 

      <div class="row justify-content-center g-3 mb-1">
        <div class="col-md-6">
          <input type="text" id="county" class="form-control form-control-sm" v-model="county" placeholder="County"/>
        </div>            
      </div>           

      <div class="row justify-content-center g-3 mb-3">
        <div class="col-md-2">
          <div class="feedback" v-if="postCodeError">{{ postCodeError }}</div>
          <input type="text" id="postCode" class="form-control form-select-sm" v-model="postCode" placeholder="PostCode"/>
        </div>  
        <div class="col-md-4">
          <div class="feedback" v-if="countryError">{{ countryError }}</div>
          <select class="form-select form-select-sm" id="country" v-model="country">
            <option value="United Kingdom" >United Kingdom</option>
            <option value="Austria" >Austria</option>
            <option value="Belarus" >Belarus</option>
            <option value="Belgium" >Belgium</option>
            <option value="Canada" >Canada</option>
            <option value="China" >China</option>
            <option value="Croatia" >Croatia</option>
            <option value="Czech Republic" >Czech Republic</option>
            <option value="Denmark" >Denmark</option>
            <option value="Estonia" >Estonia</option>
            <option value="Finland" >Finland</option>
            <option value="France" >France</option>
            <option value="Germany" >Germany</option>
            <option value="Hungary" >Hungary</option>
            <option value="Italy" >Italy</option>
            <option value="Japan" >Japan</option>
            <option value="Latvia" >Latvia</option>
            <option value="Lithuania" >Lithuania</option>
            <option value="Luxembourg" >Luxembourg</option>
            <option value="Malaysia" >Malaysia</option>
            <option value="Netherlands" >Netherlands</option>
            <option value="Norway" >Norway</option>
            <option value="Poland" >Poland</option>
            <option value="Portugal" >Portugal</option>
            <option value="Rep Ireland" >Rep Ireland</option>
            <option value="Russia" >Russia</option>
            <option value="Slovakia" >Slovakia</option>
            <option value="Slovenia" >Slovenia</option>
            <option value="Spain" >Spain</option>
            <option value="Sweden" >Sweden</option>
            <option value="Switzerland" >Switzerland</option>
            <option value="Ukraine" >Ukraine</option>
            <option value="USA" >United States of America</option>
          </select>
        </div>                       
      </div> 

      <div class="row justify-content-center g-3 mb-3">
        <div class="col-md-6">
          <div class="mb-1 input-group">
            <span class="input-group-text">
              <i class="bi bi-telephone text-secondary"></i>
            </span>
            <input type="text" id="phoneNumber" class="form-control form-control-sm" v-model="phoneNumber" placeholder="Mobile or Landline number"/>
            <!-- tooltip -->
            <span class="input-group-text">
              <span class="tt" data-bs-placement="bottom" title="Please enter a contact telephone number for urgent queries.">
                <i class="bi bi-question-circle text-muted"></i>
              </span>
            </span>
          </div>
        </div>
      </div>

      <div class="my-4 text-center">
        <button type="submit" class="btn btn-secondary">Update</button>
      </div>
    </form>

  </template>
  
  <script>
  import { ref } from "vue";
  import { useRouter } from "vue-router";
  import getUser from '@/composables/getUser'

  import { db } from '@/firebase/config'
  import { doc, getDoc,setDoc} from 'firebase/firestore'

  export default {
    name: "Address",
    setup() {
      // Get the existing data from Firestore as we have the id
      const { user } = getUser()
      const router = useRouter()
      const userId = user.value.uid
      const streetaddress = ref('')
      const streetaddressError = ref('')
      const locality = ref('')
      const postaltown = ref('')
      const postaltownError = ref('')
      const county = ref('')
      const postCode = ref('')
      const postCodeError = ref('')
      const country = ref('')
      const countryError = ref('')
      const phoneNumber = ref('')

      if (userId) {
        // get user details if they already exist       
        getDoc(doc(db, 'user-details', userId))
        .then((doc) => {
          if (doc.exists()) {
            if ('streetaddress' in doc.data()) streetaddress.value = doc.data().streetaddress
            if ('locality' in doc.data()) locality.value = doc.data().locality
            if ('postaltown' in doc.data()) postaltown.value = doc.data().postaltown
            if ('county' in doc.data()) county.value = doc.data().county
            if ('postCode' in doc.data()) postCode.value = doc.data().postCode
            if ('country' in doc.data()) country.value = doc.data().country
            if ('phoneNumber' in doc.data()) phoneNumber.value = doc.data().phoneNumber
          }   
        })
        .catch((error) => {
            console.log("Error getting user-details:", error);
        });
      }

      const validateForm = ()=> {
        // validate that the form is complete
        // if not, display error messages        
        let valid = true      
        if (streetaddress.value.length < 2) {
          streetaddressError.value = "Please enter your street address"
          valid = false
        } else {
          streetaddressError.value = ""
        }
        if (postaltown.value.length < 2) {
          postaltownError.value = "Please enter your postal town"
          valid = false
        } else {
          postaltownError.value = ""
        }
        if (postCode.value.length < 2) {
          postCodeError.value = "Please enter your post code"
          valid = false
        } else {
          postCodeError.value = ""
        }
        if (country.value.length < 2) {
          countryError.value = "Please enter your country"
          valid = false
        } else {
          countryError.value = ""
        }
        return valid
      }

      const handleSubmit = async () => {
        if (validateForm()){
          // if so, save the data to Firestore
          var userDetails = {} 
          if (phoneNumber.value) userDetails['phoneNumber'] = phoneNumber.value
          if (streetaddress.value) userDetails['streetaddress'] = streetaddress.value
          if (locality.value) userDetails['locality'] = locality.value
          if (postaltown.value) userDetails['postaltown'] = postaltown.value
          if (county.value) userDetails['county'] = county.value
          if (postCode.value) userDetails['postCode'] = postCode.value
          if (country.value) userDetails['country'] = country.value
          setDoc(doc(db, 'user-details', userId), userDetails, { merge: true })
          .catch((error) => {
            console.log("Error updating user-details:", error)
            return
          });
          router.push({ name: "shows" })
        }
      }
      
      return {streetaddress,locality,postaltown,county,postCode,country,phoneNumber,handleSubmit,
              streetaddressError,postaltownError,postCodeError,countryError}
    }
  };
  </script>
  
  <style>
 .feedback {
    color: red;
    font-size: 0.8rem;
    text-decoration: none;
  }  
  </style>
  