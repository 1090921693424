<template>
    <section id="privacy">
    <div class="container-lg">
      <div class="row g-4 justify-content-center align-items-center">
       <h3 class="text-primary">Agility Shows Online - Privacy Policy</h3>
       <p>This privacy policy is to demonstrate our firm commitment to your privacy and the protection of your information.
        We use appropriate security measures to protect against the loss, misuse and alteration of data used by our system.
        Agility Shows Online Limited will never share, sell, or rent individual personal information with anyone for their
        promotional use unless you have opted in or unless ordered by a court of law.</p>
        <p>Opt in options will be presented to you onscreen when entering any event that may wish to contact you this way.
        Information submitted to us is only available to employees managing this information for purposes of contacting you
        primarily by sending you emails based on your usage of our site.</p>
        <p>We collect your personal information from your registration and thereby you volunteering such information.
        The information we collect through the registration process is personal information that identifies you personally:</p>
        <ul class="list-unstyled ms-5 mb-3">
            <li>Your name and the names of other handlers in your household</li>
            <li>Year and Month of birth for class eligibility</li>
            <li>E-mail address</li>
            <li>Postal address</li>
            <li>Telephone and mobile numbers</li>
            <li>Kennel Club registration details of the dogs in your household</li>
        </ul>
        <p>This information is required to enter agility shows hosted on our website. This data is either processed by ourselves to
        create the relevant show paperwork or is passed electronically to a third party show processor as detailed in the show
        schedule. This information is passed onto the relevant show organisers in printed form once the show data is
        processed. Some shows use electronic ringside scoring systems and so the show data will additionally be passed to
        the supplier of such system electronically. Your name and the name of your dog will appear in results which may be
        hosted either by ourselves, the third party show processor or the host of the ringside scoring system.</p>
        <p>You can de-register at any time by sending the Agility Shows Online administrator kate@agilityshows.online an email
        or use the Contact Us page on the website. We will remove all the data we hold on you and you will no longer be able
        to enter agility shows through our service.</p>
        <p>NB: We do NOT hold any Bank details nor Credit or Debit Card information.</p>
        <p>Your usage of this website is dependent on being a registered user, and tracked through the use of session cookies.
        A cookie is a piece of data stored on the user's computer tied to information about the user and is generated at login
        time. We only use session ID cookies and do not use persistent cookies. For session ID cookies, once you close your
        browser or log out, the cookie terminates and is erased. A persistent cookie is a small text file stored on your
        computer’s hard drive for an extended period of time.</p>
        <p>Like the majority of websites, we use log files. This information may include internet protocol (IP) addresses, browser
        type, internet service provider (ISP), referring/exit pages, platform type, and date/time stamp, which we use to analyse
        trends, administer the site, and to track user's movement to gather broad demographic information for use in providing
        a better service to users. However, none of the information stored in these log files, including but not limited to IP
        addresses, is linked to any personally identifiable information.</p>
        <p>Though every effort is made to preserve your privacy, we may need to disclose personal information when required to
        by law. If it is deemed necessary that such action needs to be taken, we will comply with judicial proceedings should a
        court order or legal process be served on Agility Shows Online Limited.</p>
        <p>You are responsible for the security of your password. Make sure you keep it in a safe place and do not share it with
        others. Always remember to log out after your session ends, to ensure that others cannot access your private personal
        information. You should take this precaution even if you are not using a public computer, such as at a library or
        internet café, but even when using your private computer in your home.</p>
        <p>Any questions concerning this Privacy Policy please use the Contact Us on the website.</p>
      </div>
    </div>
  </section>
</template>
  
  <script>
  export default {
    name: "Privacy",
  };
  </script>
  
  <style>
  </style>
  