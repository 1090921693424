<template>
  <!-- Main panel content-->
    <section> 
      <div class="float-end">
        <span class="tt link" data-bs-placement="bottom" title="Add another dog">
        <button type="button" class="btn btn-light" data-bs-toggle="modal" data-bs-target="#dogModal" data-bs-style="add">
          <h3><i class="bi bi-plus-circle"></i></h3>
        </button>
        </span>
      </div>
      <ul class="list-unstyled">
        <div v-for="dog in dogs" :key="dog.id">
        <li class="list-group-item d-flex align-items-start">
          <span class="tt link" data-bs-placement="bottom" title="Remove this dog from your list.">
            <i class="bi bi-trash" data-bs-toggle="modal" data-bs-target="#dogModal" data-bs-style="delete" :data-bs-id="dog.id" ></i>&nbsp;
          </span>
          <span class="tt link" data-bs-placement="bottom" title="Edit this dog.">
            <i class="bi bi-pencil-square" data-bs-toggle="modal" data-bs-target="#dogModal" data-bs-style="edit" :data-bs-id="dog.id"></i>&nbsp;
          </span>
          <small>{{dog.petName}}&nbsp;</small>
          <small  class="text-muted">&nbsp;({{getAgeGroup(dog.dob)}})</small >
        </li>
      </div>
      </ul>
  
    </section>
  
  <!-- pop up modal for add/edit/delete -->
  <div class="modal fade" ref="dogModal" id="dogModal" tabindex="-1" aria-labelledby="dogModalTitle" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="dogModalTitle" ref="dogModalTitle">Dog</h5>
          <button id="dismissDogModal" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" ref="dogSimpleContent">
        <span ref="dogModalMessage"></span>
        </div>
        <form ref="dogForm" @submit.prevent="processDog">
          <div ref="dogFormContent" class="modal-body">
            <div class="row g-3">            
              <div class="col-sm-6 mb-3">
                <label for="dogFrmName" class="form-label form-label-sm">Pet Name:</label>
                <input type="text" class="form-control form-control-sm" id="dogFrmName" v-model="petName" placeholder="Pet name">
                <div class="feedback" v-if="petNameError">{{ petNameError }}</div>
              </div>
              <div class="col-sm-4 mb-3">
                <label for="dogFrmDob" class="form-label form-label-sm">Date of Birth:</label>
                <input type="date" class="form-control form-control-sm" id="dogFrmDob" v-model="dob">
              </div>
            </div>
            <div class="mb-3">
              <input type="text" class="form-control form-control-sm" id="dogFrmkcName" v-model="kcName" placeholder="KC registered name">
              <div class="feedback" v-if="kcNameError">{{ kcNameError }}</div>
            </div>
            <div class="mb-3">
              <input type="text" class="form-control form-control-sm" id="dogFrmMicrochip" v-model="microchip" placeholder="Microchip number">
              <div class="feedback" v-if="microchipError">{{ microchipError }}</div>
            </div>
            <div class="mb-3">
              <input type="text" class="form-control form-control-sm" id="dogFrmBreed" v-model="breed" placeholder="Breed">
              <div class="feedback" v-if="breedError">{{ breedError }}</div>
            </div>
            <div class="row g-3">
              <div class="col-sm-6 mb-3">
                <select class="form-select form-select-sm" id="height" v-model="kcHeight" placeholder="Measured height">
                  <option v-for="height in heights" :value="height.id">
                    {{ height.name }}
                  </option>
                </select>
              </div>
              <div class="col-sm-6 mb-3">
                <select class="form-select form-select-sm" id="grade" v-model="kcGrade">
                  <option v-for="grade in grades" :value="grade.id">
                    {{ grade.name }}
                  </option>
                </select>
              </div>
            </div>
            <input type="hidden" ref="dogFormStyle" value="">
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-danger" ref="dogDeleteBtn" data-bs-dismiss="modal" @click.prevent="deleteDog">Remove</button>
            <button type="submit" class="btn btn-success" ref="dogSaveBtn">Save</button>
            <button type="submit" class="btn btn-success" ref="dogUpdateBtn">Update</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  
  </template>    
  
  <script>
  import { ref, onMounted } from "vue";
  import dayjs from 'dayjs'
  import getUser from '@/composables/getUser'
  import getMyCollection from '@/composables/getMyCollection'
  import gradeList from '@/composables/getGrades'
  import heightList from '@/composables/getHeights'
  import { db } from '@/firebase/config'
  import { addDoc, setDoc, deleteDoc, doc, collection } from "@firebase/firestore"
  
  export default {
    name: "Dogs",
    setup() {
      // Get the existing data from Firestore as we have the id
      const { user } = getUser()
      const userId = user.value.uid
      const colRef = collection(db, 'dogs')
      const grades = gradeList()
      const heights = heightList()
      // Form data refs
      const kcName = ref('')
      const kcNameError = ref('')
      const kcGrade = ref('Grade 3') 
      const kcHeight = ref('Large')
      const microchip = ref('')
      const microchipError = ref('')
      const breed = ref('')
      const breedError = ref('')
      const petName = ref('')
      const petNameError = ref('')
      const dob = ref(dayjs().subtract(30,'year').format('YYYY-MM-DD'))
      // Modal refs for modifying the modal display
      const dogModal = ref(null)
      const dogForm = ref(null)
      const dogFormStyle = ref(null)
      const dogSimpleContent = ref(null)
      const dogFormContent = ref(null)
      const dogModalMessage = ref(null)
      const dogModalTitle = ref(null)
      const dogModalMsg = ref(null)
      const dogDeleteBtn = ref(null)
      const dogUpdateBtn = ref(null)
      const dogSaveBtn = ref(null)
   
      // get list of this account's dogs if they already exist       
      // We use the getMyCollection composable to fetch the data from firestore
      const { documents: dogs }  = getMyCollection('dogs',userId)
  
      // Function to return 'Adult', 'Senior' or 'Junior' based on the dog's age
      const getAgeGroup = (dob) => {
        if (!dob) return 'Unknown age'
        const birthday = dayjs(dob)
        const today = dayjs()
        const age = today.diff(birthday,'year',true)
        if (age < 1.5) {
          return 'Pre-Comp'
        } else if (age < 9) {
          return 'Active'
        } else {
          return 'Veteran'
        }
      }
  
      const validateForm = ()=> {
        petNameError.value = ''
        if (petName.value.length < 2) {
        petcNameError.value = 'Please enter a valid pet name'
        }
        kcNameError.value = ''
        if (kcName.value.length < 5) {
          kcNameError.value = 'Please enter a valid KC name'
        }
        microchipError.value = ''
        if (microchip.value.length < 14) {
          microchipError.value = 'Please enter a valid microchip number'
        }
        breedError.value = ''
        if (breed.value.length < 3) {
          breedError.value = 'Please enter a valid breed'
        }
        if (kcNameError.value || petNameError.value || microchipError.value || breedError.value) {
          return false
        } else {
          return true
        }
      }
  
      const validateNonDuplicate = () => {
        // Check to see if this name is already in the dogs list
        if (dogs.value.find(dog => dog.kcName == kcName.value) ) {
          kcNameError.value = 'This dog is already in your list'
        }
        if (kcNameError.value ) {
          return false
        } else {
          return true
        }
      }
  
      const processDog = (event) => {
        const dog = {
            kcName: kcName.value,
            petName: petName.value,
            breed: breed.value,
            microchip: microchip.value,
            kcGrade: kcGrade.value,
            kcHeight: kcHeight.value,
            owner: userId,
            dob: dob.value
          }
        if (validateForm()) {
          if ((dogFormStyle.value.value == 'add') && validateNonDuplicate()) {
            try {
              addDoc(colRef, dog)
            } catch (err) {
              console.log(err.message)
            }
          } else if (dogFormStyle.value.value == 'edit') {
            try {
              setDoc(doc(db, 'dogs', event.srcElement.id), dog)
            } catch (err) {
              console.log(err.message)
            }
          }
          let dismiss = document.querySelector('#dismissDogModal')
          dismiss.click()
        }
      } 
  
      const deleteDog = (event) => {
        // delete the dog with id==event.srcElement.name from firestore
        if(event.srcElement.id) {
          deleteDoc(doc(db, 'dogs', event.srcElement.id))
        }
      }
  
      onMounted(() => { 
        // Now we are mounted add an event listener to the modal reveal event
        dogModal.value.addEventListener('show.bs.modal', function (event) {
          // Query the button that triggered the modal for why it was triggered
          var button = event.relatedTarget
          var formStyle = button.getAttribute('data-bs-style')
          dogFormStyle.value.value = formStyle
           var visible = 'block'
           var hidden = 'none'
           if (formStyle == 'delete') {
             dogFormContent.value.style.display = hidden
             dogSimpleContent.value.style.display = visible
             dogModalTitle.value.textContent = 'Are you sure?'
             var deleteName = dogs.value.find(dog => dog.id == button.getAttribute('data-bs-id')).petName
             dogModalMessage.value.textContent = 'Remove ' + deleteName + '?'
             // Then update the delete button to point to the correct dog id
             dogDeleteBtn.value.setAttribute('id', button.getAttribute('data-bs-id'))        
             dogDeleteBtn.value.style.display = visible
             dogUpdateBtn.value.style.display = hidden
             dogSaveBtn.value.style.display = hidden
           } else if (formStyle == 'add') {
             // show the form
             dogFormContent.value.style.display = visible
             dogSimpleContent.value.style.display = hidden
             dogModalTitle.value.textContent = 'Add dog'
             dogForm.value.reset()
             dob.value = dayjs().subtract(30,'year').format('YYYY-MM-DD')
             dogDeleteBtn.value.style.display = hidden
             dogUpdateBtn.value.style.display = hidden
             dogSaveBtn.value.style.display = visible
           } else if (formStyle == 'edit') {
             dogFormContent.value.style.display = visible
             dogSimpleContent.value.style.display = hidden
             dogModalTitle.value.textContent = 'Edit dog'
             // populate the form with the dog's details
             var dog = dogs.value.find(dog => dog.id == button.getAttribute('data-bs-id'))
             if (dog.kcName) kcName.value = dog.kcName
             if (dog.petName) petName.value = dog.petName
             if (dog.dob) dob.value = dog.dob
             if (dog.microchip) microchip.value = dog.microchip
             if (breed.value) breed.value = dog.breed
             if (kcGrade) kcGrade.value = dog.kcGrade
             if (kcHeight) kcHeight.value = dog.kcHeight
             // Then update the delete button to point to the correct dog id
             dogForm.value.setAttribute('id', button.getAttribute('data-bs-id'))
             dogDeleteBtn.value.style.display = hidden
             dogUpdateBtn.value.style.display = visible
             dogSaveBtn.value.style.display = hidden
           }
         })
      })
  
      return { dogs, grades, heights, kcName, kcNameError, breed, breedError, petName, petNameError,  kcGrade, kcHeight, dob, 
        microchip, microchipError, deleteDog, processDog, getAgeGroup, 
        dogModal, dogFormStyle, dogSimpleContent, dogFormContent, dogModalMessage, dogModalTitle,
        dogModalMsg, dogDeleteBtn, dogUpdateBtn, dogSaveBtn, dogForm }
    }
  }
  </script>
  
  <style>
  .link {
    cursor: pointer;
  }
  .feedback {
      color: red;
      font-size: 0.8rem;
      text-decoration: none;
    }
  </style>
  