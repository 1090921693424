const getHeights = (c) => {
  return [
    {
      "name" : "Large",
      "id" : "L"
    },
    {
      "name" : "Intermediate",
      "id" : "I"
    },
    {
      "name" : "Medium",
      "id" : "M"
    },
    {
      "name" : "Small",
      "id" : "S"
    },
    // {
    //   "name" : "Micro",
    //   "id" : "XS"
    // }
  ]

}
export default getHeights
