<template>
  <!-- register form -->
  <section id="register">
      <div class="container-lg">
        
        <div class="text-center">
          <p class="lead">Register a new ASO account</p>
        </div>
        <div class="row justify-content-center my-5">
          
          <form @submit.prevent="handleSubmit">
            <div class="row justify-content-center g-3 mb-2">

              <div class="col-md-8">
                <label for="forename" class="form-label">Forename:</label>
                <div class="feedback" v-if="forenameError">{{ forenameError }}</div>
                <input type="text" id="forename" class="form-control" v-model="forename" placeholder="I'm called..."/>
              </div>
            </div>

            <div class="row justify-content-center g-3 mb-3">
              <div class="col-md-8">
                <label for="familyname" class="form-label">Surname:</label>
                <div class="feedback" v-if="surnameError">{{ surnameError }}</div>
                <input type="text" id="familyname" class="form-control" v-model="familyname" placeholder="or family name..."/>
              </div> 
            </div>

            <div class="row justify-content-center g-3 mb-3">
              <div class="col-md-8">
                <label for="emailAddress" class="form-label">e-Mail address:</label>
                <div class="feedback" v-if="emailError">{{ emailError }}</div>
                <div class="mb-1 input-group">
                  <span class="input-group-text">
                    <i class="bi bi-envelope text-secondary"></i>
                  </span>
                  <input type="text" id="emailAddress" class="form-control" v-model="emailAddress" placeholder="(will be your login name)"/>
                  <!-- tooltip -->
                  <span class="input-group-text">
                    <span class="tt" data-bs-placement="bottom" title="Please enter a valid email address for correspondence. This will also become your account login name.">
                      <i class="bi bi-question-circle text-muted"></i>
                    </span>
                  </span>
                </div>
              </div>
            </div>

            <div class="row justify-content-center g-3">
              <div class="col-md-8">
                <label for="password" class="form-label">Password:</label>
                <div class="feedback" v-if="passwordError">{{ passwordError }}</div>
                <div class="mb-1 input-group">
                  <span class="input-group-text">
                    <i class="bi bi-unlock text-secondary"></i>
                  </span>
                  <input type="password" id="password" v-model="password" class="form-control" />
                  <!-- tooltip -->
                  <span class="input-group-text">
                    <span class="tt" data-bs-placement="bottom" title="Passwords must be between 8 characters and contain a mixture of letters, numbers and symbols.">
                      <i class="bi bi-question-circle text-muted"></i>
                    </span>
                  </span>
                </div> 
              </div>
            </div>

            <div class="row justify-content-center g-3 mb-3">
              <div class="col-md-8">  
                <label for="confirmPassword" class="form-label">Confirm password:</label>
                <div class="mb-1 input-group">
                  <span class="input-group-text">
                    <i class="bi bi-unlock text-secondary"></i>
                  </span>
                  <input type="password" id="confirmPassword" v-model="confirmPassword" class="form-control" />
                  <!-- tooltip -->
                  <span class="input-group-text">
                    <span class="tt" data-bs-placement="bottom" title="Your passwords must match.">
                      <i class="bi bi-question-circle text-muted"></i>
                    </span>
                  </span>
                </div>   
              </div>
            </div>
            
            <div class="row justify-content-center">
              <div class="form-check col-6">
                <input class="form-check-input" type="checkbox" id="tandcCheck" v-model="tandcCheck">
                <label class="form-check-label feedback" for="tandcCheck">
                  I have read and agree to the ASO <router-link class="feedback" :to="{name:'tandc'}">Terms and Conditions of service</router-link>
                </label>
              </div>
            </div>

            <div class="my-4 text-center">
              <button type="submit" class="btn btn-secondary" :disabled="!tandcCheck">Next ></button>
            </div>
          </form>
        </div>
      </div>
    </section>
  </template>
  
  <script>
import { ref } from "vue"
import { useRouter } from "vue-router";
import useSignup from "../../composables/useSignup"
import { db } from '@/firebase/config'
import { doc, collection, addDoc, setDoc } from "@firebase/firestore";
import getUser from '@/composables/getUser'

  export default {
    name: "Register",
     setup() {
      const router = useRouter()
      const { user } = getUser()
      const { signup, error } = useSignup()
      const forename = ref('')
      const familyname = ref('')
      const emailAddress = ref('')
      const password = ref('')
      const confirmPassword = ref('')
      const tandcCheck = ref(false)
      const passwordError = ref('')
      const forenameError = ref('')
      const surnameError = ref('')
      const emailError = ref('')

      const validateForm = () => {
        let valid = true
        if (forename.value.length < 2) {
          forenameError.value = "Please enter your known name"
          valid = false
        } else {
          forenameError.value = ""
        }
        if (familyname.value.length < 2) {
          surnameError.value = "Please enter your family name"
          valid = false
        } else {
          surnameError.value = ""
        }
        const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        if (!emailRegex.test(emailAddress.value)) {
          emailError.value = "Please enter a valid email address"
          valid = false
        } else {
          emailError.value = ""
        }
        if (password.value.length < 8) {
          passwordError.value = "Please enter a password of at least 8 characters"
          valid = false
        } else {
          passwordError.value = ""
        }
        if ((password.value !== confirmPassword.value) && (passwordError.value === "") ) {
          passwordError.value = "Passwords do not match"
          valid = false
        } 
        return valid
      }

      const handleSubmit = async () => {
        if (validateForm()){
          const fullName = forename.value + " " + familyname.value
          await signup( emailAddress.value, password.value, fullName)
          if (!error.value  ) {
            addDoc(collection(db, 'handlers'), {owner: user.value.uid, name: fullName })
            setDoc(doc(db, 'user-details',user.value.uid), {email:emailAddress.value , role: 'user' })
            router.push({ name: "address" })
          }
        }
      };

       return {handleSubmit, forename, familyname, emailAddress, password, confirmPassword, tandcCheck, passwordError, forenameError, surnameError, emailError}
     },

  }
  </script>
  
  <style>
  .feedback {
    color: red;
    font-size: 0.8rem;
    text-decoration: none;
  }
  </style>
  