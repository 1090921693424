import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// Bootstrap 5 imports
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './css/main.min.css';
import './css/aso.css';

// firebase imports
import { auth } from './firebase/config'
import { onAuthStateChanged } from 'firebase/auth'

let app 
// Tweak to ensure that the Firebase login status is known before mounting the app
onAuthStateChanged(auth, () => {
  if (!app) {
    app = createApp(App).use(router).mount('#app')
  }
})
