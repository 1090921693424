<template>
  <!-- login form -->
  <section id="contact">
    <div class="container-lg">
      
      <div class="text-center">
        <p class="lead">Login</p>
      </div>
      <div class="row justify-content-center my-5">
        <div class="col-lg-6">
          
          <form @submit.prevent="handleSubmit">
            <label for="email" class="form-label">Login name:</label>
            <div class="input-group mb-2">
              <span class="input-group-text">
                <i class="bi bi-person-fill text-secondary"></i>
              </span>
              <input type="text" id="email" class="form-control" v-model="email" placeholder="e.g. joan@example.com" />
              <!-- tooltip -->
              <span class="input-group-text">
                <span class="tt" data-bs-placement="bottom" title="If you have forgotten your login name it is likely to be your email address">
                  <i class="bi bi-question-circle text-muted"></i>
                </span>
              </span>
            </div>
            <label for="password" class="form-label">Password:</label>
            <div class="mb-2 input-group">
              <span class="input-group-text">
                <i class="bi bi-unlock text-secondary"></i>
              </span>
              <input type="password" id="password" class="form-control" v-model="password"/>
              <!-- tooltip -->
              <span class="input-group-text">
                <span class="tt" data-bs-placement="left" title="If you have forgotten your password please use the password reset link.">
                  <i class="bi bi-question-circle text-muted"></i>
                </span>
              </span>
            </div> 
            <div class="my-4 text-center">
              <button type="submit" class="btn btn-primary">Login</button>
            </div>
            <div v-if="error" class="alert alert-danger" role="alert">
              {{ error }}
            </div>
          </form>

          <div class="mb-2">
            <span class="text-muted">
              Forgotten your password? You can reset it 
            </span>
            <a href="reset">here</a>
          </div>
          <div>
            <span class="text-muted">
              Don't have an account? Register  
            </span>
            <router-link :to="{name:'register'}">here</router-link>
          </div>
        </div>
      </div>
    </div>
  </section> 
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import useLogin from '@/composables/useLogin';

export default {
  name: 'Login',
  setup() {
    const email = ref('');
    const password = ref('');
    const router = useRouter();
    const { login, error } = useLogin();
    const handleSubmit = async () => {
      await login(email.value, password.value);
      console.log(error.value);
      if (!error.value) {
        router.push({ name: 'shows' })
      }
    };
    return {
      email,
      password,
      handleSubmit,
      error,
    };
  }
}
</script>