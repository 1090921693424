const getGrades = (c) => {
  return [
    {
      "name" : "Grade 1",
      "id" : "1"
    },
    {
      "name" : "Grade 2",
      "id" : "2"
    },
    {
      "name" : "Grade 3",
      "id" : "3"
    },
    {
      "name" : "Grade 4",
      "id" : "4"
    },
    {
      "name" : "Grade 5",
      "id" : "5"
    }
  ]

}
export default getGrades
