<template>
  <!-- show list -->
  <section id="eventlist">
    <div class="container-lg">
      <div class="text-center">
        <p class="lead mb-0">Upcoming Shows on ASO
        </p>
      </div>

      <div class="btn-group small mt-3" role="group">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="chkKCCH" @click="toggleInclusion('KC-Ch')" checked>
          <label class="form-check-label" for="chkKCCH">Champ</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="chkKCP" @click="toggleInclusion('KC-P')" checked>
          <label class="form-check-label" for="chkKCP">Premier</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="chkKC" @click="toggleInclusion('KC')" checked>
          <label class="form-check-label" for="chkKC">KC</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="chkJer" @click="toggleInclusion('Jer')" checked>
          <label class="form-check-label" for="chkJer">Jersey</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="chkInd" @click="toggleInclusion('Ind')" checked>
          <label class="form-check-label" for="chkInd">Ind</label>
        </div>  
      </div>

      <div class="row justify-content-center my-2">
        <table class="table table-borderless table-sm table-responsive" id="eventtable" >
          <thead>
            <td>Date</td>
            <td>Rules</td>
            <td>&nbsp;</td>
            <td>Location</td>
            <td colspan="3">Entries close</td>
          </thead>  
          <tbody v-for="event in filteredEvents" :key="event.id">
            <template v-if="monthChanged(event)">
            <tr class="table-dark">
                <td colspan="7">{{monthName(event)}}</td>
            </tr>
            </template>
            <tr :class="rowClass(event)">
              <td>{{ daysOfMonth(event) }}</td>
              <td>{{ event.type }}</td>
              <td>{{ event.name }}</td>
              <td>{{ event.location }}</td>
              <td>{{ entryStatus(event) }}</td>
              <td v-if="acceptingEntries(event)" class="small text-danger"><a :href="'enterShow/'+event.id" alt="Enter"><img  src="@/assets/go.png" alt="Enter"></a></td>
              <td v-else>&nbsp;</td>
              <td><a :href="event.schedule"><img v-if="event.schedule" src="@/assets/docs.png" alt="Schedule"></a></td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </section>
</template>

<script>
import getSnapshot from '@/composables/getSnapshot'
import dayjs from 'dayjs'

export default {
  name: "Shows",
  setup() {
    // We use the getCollection composable to fetch the data from the ASO server
    const { documents: eventData } = getSnapshot('competitions')
    // return the collection and current month as non-reactive data!
    return { eventData, currentMonth : -1}
  },
  data() {
    return {
      // return the current filter settings as reactive data
      ruleFilter: ["KC","KC-Ch","KC-P","Jer", "Ind"],
    }
  },
  methods: {
    // Switch the class of a row depending upon the contents of event.type
    rowClass(event) {
      switch (event.type) {
        case "KC-Ch": return "table-success small"
        case "KC-P": return "table-info small"
        case "KC": return "table-success small"
        case "Jer": return "table-warning small"
        case "Ind": return "table-danger small"
        default: return "table-light small"
      }
    },
    monthName(event) {
      // return the full month name and year of the given event
      return dayjs(event.startDate).format('MMMM YYYY')
    },
    monthChanged(event) {
      // Does the month of the current event match the last one we looked at?
      if (dayjs(event.startDate).month() != this.currentMonth) {
        this.currentMonth = dayjs(event.startDate).month()
        return true
      } else {
        return false
      }
    },
    daysOfMonth(event) {
      // Extract the day numbers from a full event date and format as xx-yy
      if (dayjs(event.endDate).format('DD/MM/YY') != dayjs(event.startDate).format('DD/MM/YY')) {
        return dayjs(event.startDate).format('ddd D') + "-" + dayjs(event.endDate).format('ddd D')
      } else{
        return dayjs(event.startDate).format('ddd D')
      }
    },
    entryStatus(event) {
      // return the helper text for entries closing: Closed, closing soon, or the date it closes
      const dif = Math.floor((dayjs(event.entriesClose)-dayjs()) / 86400000)
      switch (true) {
        case (dif == 0) : 
          return "Today!"
          break
        case ((dif < 14) && (dif > 0)) : 
          return "in " + dif + " days"
          break            
        case (dif <= 0): 
          return "Closed"
          break
        default : return dayjs(event.entriesClose).format('DD/MM/YY')
      }
    },
    acceptingEntries(event) {
      // return whether the 'ENTER' link should be shown (Currently straight data but could be a calculated field) 
      return event.entriesOpen
    },
    toggleInclusion(targetRule) {
      if (this.ruleFilter.includes(targetRule)) {
        this.ruleFilter = this.ruleFilter.filter(rule => rule != targetRule)
      } else {
        this.ruleFilter.push(targetRule)
      }
    }
  },
  computed: {
    // return filtered shows based upon the criteria
    filteredEvents() {
      if (this.eventData) {
        return this.eventData.filter(event => this.ruleFilter.includes(event.type) )
      } else {
        return this.eventData
      }
    }
  }
};
</script>

<style>
</style>
