import { ref } from 'vue'
// firebase imports
import { db } from '../firebase/config'
import { collection,getDocs } from 'firebase/firestore'
// Get a collection from Firestore without subscribing to updates
const getSnapshot = (c) => {
  const documents = ref(null)

  // collection reference
  const colRef = collection(db, c)
  getDocs(colRef).then((snapshot) => {
    const results = []
    snapshot.forEach((doc) => {
      results.push({ ...doc.data(), id: doc.id })
      });
      documents.value = results
    }) 
  return { documents }
}

export default getSnapshot
